//
//
//
//
//
//
//
//
//
//
//
//
//
//


import HalykInfo from "~/components/common/halykInfo/index.vue";
import BnplInfo from "~/components/pages/productCard/ProductSidebar/Components/BnplInfo.vue";

export default {
    name: "ProductMobileBottom",
    components: {
        BnplInfo,
        HalykInfo,
        ProductPdp: () => import("@/components/pages/productCard/ProductContent/Components/ProductPdp/index"),
        ProductBuyFavorite: () => import("@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index"),
        ProductSizes: () => import("@/components/pages/productCard/ProductContent/Components/ProductSizes/index")
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
